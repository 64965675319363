.container {
  display: grid;
  height: 100vh;
  grid-template-columns: auto minmax(200px, 1628px) auto;
  grid-template-rows: 2em auto 4em;
}

.grey {
  background-color: rgba(231, 231, 231, 1);
}

.blue {
  background-color: #0077c8;
}

.welcome-container {
  margin: 7em 0 2em 0;
}

.body {
  padding: 0 2em;
}

.big-blue-text {
  color: #0077c8;
  margin: 0 0 0 0.2em;
  font-size: 32px;
  font-family: 'Arial';
}

@media (max-width: 600px) {
  .welcome-container {
    margin: 2em 0 2em 0;
  }

  .body {
    padding: 0;
  }

  .container {
    display: grid;
    height: 100vh;
    grid-template-columns: auto minmax(200px, 1628px) auto;
    grid-template-rows: 2em auto 6em;
  }
}
