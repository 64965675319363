.main {
  color: #58535b;
  font-family: "Arial";
  font-weight: 500;
  font-size: 12pt;
  margin: 1em;
}

.main:hover {
  text-decoration: underline;
  cursor: pointer;
}
