.container {
  background-color: #0077c8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  background-color: #0077c8;
  height: 100%;
}

.left-half {
  display: flex;
  flex-wrap: wrap;
}

.right-half {
  display: flex;
  align-items: center;
}

.left-column {
  background-color: #0077c8;
}

.right-column {
  background-color: #0077c8;
}

.social-icon {
  margin: 0 1em;
  cursor: pointer;
}

.white-text {
  margin: 0 0.5em;
  color: white;
  font-family: "Arial";
  font-weight: bold;
  font-size: 10pt;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
